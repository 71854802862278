import React from 'react';

export default ({ color = '#fff', className, style }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    className={className}
    style={style}
  >
    <g fill="none" fillRule="evenodd">
      <g fill={color} fillRule="nonzero">
        <g>
          <path
            d="M32 16c0-8.836-7.164-16-16-16S0 7.164 0 16s7.164 16 16 16 16-7.164 16-16zm-20.297 0v-3.062h1.877v-1.851c0-2.498.746-4.297 3.48-4.297h3.253v3.054h-2.29c-1.147 0-1.408.763-1.408 1.561v1.533h3.529L19.663 16h-3.048v9.235H13.58V16h-1.877z"
            transform="translate(-1677.000000, -3543.000000) translate(1677.000000, 3543.000000)"
          />
        </g>
      </g>
    </g>
  </svg>
);
