import React from 'react';

export default ({ color = '#fff', className, style }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    className={className}
    style={style}
  >
    <g fill="none" fillRule="evenodd">
      <g fill={color} fillRule="nonzero">
        <g>
          <g>
            <path
              d="M16 0C7.2 0 0 7.2 0 16s7.2 16 16 16 16-7.2 16-16S24.8 0 16 0zm8 16.6c0 1.3-.2 2.6-.2 2.6s-.2 1.1-.6 1.6c-.6.6-1.3.6-1.6.7-2.2.2-5.6.2-5.6.2s-4.2 0-5.4-.2c-.4-.1-1.2 0-1.8-.7-.5-.5-.6-1.6-.6-1.6S8 17.9 8 16.6v-1.2c0-1.3.2-2.6.2-2.6s.2-1.1.6-1.6c.6-.6 1.3-.6 1.6-.7 2.2-.2 5.6-.2 5.6-.2s3.4 0 5.6.2c.3 0 1 0 1.6.7.5.5.6 1.6.6 1.6s.2 1.3.2 2.6v1.2z"
              transform="translate(-1729.000000, -3543.000000) translate(1677.000000, 3543.000000) translate(52.000000, 0.000000)"
            />
            <path
              d="M14.3 18.1L18.7 15.8 14.3 13.6z"
              transform="translate(-1729.000000, -3543.000000) translate(1677.000000, 3543.000000) translate(52.000000, 0.000000)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
