import React from 'react';

export default ({ color = '#fff', className, style }) => (
  <svg
    width="32px"
    height="32px"
    viewBox="0 0 32 32"
    version="1.1"
    className={className}
    style={style}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-1545.000000, -2868.000000)" fill={color} fillRule="nonzero">
        <g transform="translate(1545.000000, 2868.000000)">
          <path d="M13.632,23.7413333 L16,21.3733333 L20.4573333,21.3733333 L23.244,18.5866667 L23.244,9.672 L9.87066667,9.672 L9.87066667,21.3733333 L13.632,21.3733333 L13.632,23.7413333 Z M18.7866667,12.876 L20.4586667,12.876 L20.4586667,17.7466667 L18.7866667,17.7466667 L18.7866667,12.876 Z M14.328,12.876 L16,12.876 L16,17.7466667 L14.328,17.7466667 L14.328,12.876 Z M16,0 C7.164,0 0,7.164 0,16 C0,24.836 7.164,32 16,32 C24.836,32 32,24.836 32,16 C32,7.164 24.836,0 16,0 Z M24.916,19.4226667 L20.04,24.2986667 L16.4186667,24.2986667 L14.0493333,26.6666667 L11.5426667,26.6666667 L11.5426667,24.2986667 L7.084,24.2986667 L7.084,11.204 L8.33866667,8 L24.916,8 L24.916,19.4226667 L24.916,19.4226667 Z" />
        </g>
      </g>
    </g>
  </svg>
);
