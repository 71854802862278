import React from 'react';

export default ({ color = '#fff', className, style }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    className={className}
    style={style}
  >
    <g fill="none" fillRule="evenodd">
      <g fill={color} fillRule="nonzero">
        <g>
          <g>
            <path
              d="M12.9 16c0 1.712 1.388 3.1 3.1 3.1 1.712 0 3.1-1.388 3.1-3.1 0-1.108-.59-2.13-1.55-2.685-.96-.553-2.14-.553-3.1 0-.96.554-1.55 1.577-1.55 2.685zM19.6 12.9h2.2c.2 0 .4-.2.4-.4v-2.2c0-.2-.2-.4-.4-.4h-2.2c-.2 0-.4.2-.4.4v2.2c-.1.2.1.4.4.4z"
              transform="translate(-1833.000000, -3543.000000) translate(1677.000000, 3543.000000) translate(156.000000, 0.000000)"
            />
            <path
              d="M16 0C7.2 0 0 7.2 0 16s7.2 16 16 16 16-7.2 16-16S24.8 0 16 0zm8 22.1c0 1-.9 1.9-1.9 1.9H9.9c-1 0-1.9-.8-1.9-1.9V9.9C8 8.8 8.9 8 9.9 8h12.2c1 0 1.9.8 1.9 1.9v12.2z"
              transform="translate(-1833.000000, -3543.000000) translate(1677.000000, 3543.000000) translate(156.000000, 0.000000)"
            />
            <path
              d="M20.9 16c0 2.7-2.2 4.9-4.9 4.9s-4.9-2.2-4.9-4.9c0-.4.1-.9.2-1.3H9.8v7c0 .2.2.4.4.4h11.5c.2 0 .4-.2.4-.4v-7h-1.5c.2.4.3.9.3 1.3z"
              transform="translate(-1833.000000, -3543.000000) translate(1677.000000, 3543.000000) translate(156.000000, 0.000000)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
